export default class GlobalLoading {
	constructor() {
		this.$view = $('.cmn-global-loading');
		this.$view.line = this.$view.find('.logo-line');
		this.deferred = new $.Deferred();
		this.loaded = this.deferred.promise();

		if(
			window.performance.navigation.type == '1' ||
			window.performance.navigation.type == '2' ||
			document.referrer.indexOf(location.hostname) >= 0
		) {
			this.$view.remove();
			this.deferred.resolve();
		}
		if(!this.$view.get(0)) {
			return;
		}
		this.load();
	}
	load() {
		let def_loading = new $.Deferred;
		let def_drawsvg = new $.Deferred;

		$(window).on('load', () => {
			def_loading.resolve();
		});
		this.$view.line.drawsvg({duration: 1500, stagger: 0, callback: () => {
			def_drawsvg.resolve();
		}});
		setTimeout(() => {
			this.$view.addClass('standby');
			this.$view.line.drawsvg('animate');
		}, 500);
		$.when(
			def_loading.promise(),
			def_drawsvg.promise()
		).done(() => {
			this.$view.addClass('loaded');
			this.deferred.resolve();
		});
	}
	is() {
		return this.$view.get(0);
	}
}
export default class SmoothScroll {
	static instances = [];

	static init() {
		$('[data-smoothscroll]').each((index, item) => {
			let selector = $(item).attr('href');

			if(selector) {
				selector = selector.replace(/^.+#/, '#');
			} else {
				selector = $('body');
			}
			if(!$(selector).get(0)) {
				return;
			}
			this.instances.push(new SmoothScroll($(item), $(selector)));
		})
	}
	constructor($trigger, $target) {
		this.$trigger = $trigger;
		this.$target = $target;
		this.$trigger.click(this.click.bind(this))
	}
	click(e) {
		e.preventDefault();
		this.scroll()
	}
	scroll(duration = 400) {
		let scrollTop = this.$target.offset().top;
		let hash = this.$trigger.data('hash');
		
		if(!this.$target.closest('.cmn-anchorpoint').get(0)) {
			if(window.common.device == 'pc') {
				scrollTop -= 60;
			}
		}
		$('html,body').stop().animate({scrollTop : Math.max(0, scrollTop)}, duration, function() {
			if(hash) {
				location.hash = hash;
			}
		});
	}
}
export default class GlobalHeader {
	constructor(common) {
		this.common = common;
		this.$view = $('.cmn-global-header');
		this.$view.menu = this.$view.find('.menu');
		this.$view.burger = this.$view.find('.burger');

		this.$view.burger.click(() => {
			this.menuOpen();
		});
		this.$view.menu.find('[data-smoothscroll]').click(() => {
			this.menuOpen(false);
		});
		this.common.$view.scroll(() => {
			this.scrollEventHandler();
		});
	}

	menuOpen(activate = null) {
		if(activate === null) {
			activate = !this.$view.menu.hasClass('is-active');
		}
		if(activate) {
			this.$view.menu.addClass('is-active');
		} else {
			this.$view.menu.removeClass('is-active');
		}
	}

	scrollEventHandler() {
		if(this.common.$view.scrollTop() > 10) {
			this.$view.addClass('is-scroll');
		} else {
			this.$view.removeClass('is-scroll');
		}
	}
}
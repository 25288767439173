export default class BreadkPoint {
	static points = [];
	static device = null;

	static init(points, eventname = 'resize:break') {
		Object.keys(points).forEach(key => {
			this.points.push({ device : key, minwidth : points[key] });
		});
		this.points.sort((a, b) => {
			if(a.minwidth > b.minwidth) return 1;
			if(a.minwidth < b.minwidth) return -1;
			return 0;
		});
		this.eventname = eventname;
		this.resizeEventListener();
		$(window).resize(this.resizeEventListener.bind(this));
	}
	static resizeEventListener() {
		let device = null;

		this.points.forEach(point => {
			if(window.matchMedia(`(min-width: ${point.minwidth}px)`).matches) {
				device = point.device;
			}
		})
		if(this.device != device) {
			this.device = device;
			$(window).trigger(this.eventname);
			$(window).trigger(this.eventname + ':' + this.device);
		}
	}
}
export default class ScrollImage {
	static instances = [];
	static init() {
		$('[data-scrollimage]').each((i, item) => {
			this.instances.push(new ScrollImage($(item)));
		})
	}
	constructor($view) {
		this.$view = $view;
		this.$view.img = this.$view.find('img');

		$(window).scroll(this.scroll.bind(this));
		$(window).resize(this.scroll.bind(this));
		this.scroll();
	}
	scroll() {
		var wT = $(window).scrollTop();
		let wH = $(window).height();
		let vT = this.$view.offset().top;
		let vH = this.$view.height();
		let vh = this.$view.img.height();

		let ratio = (vT - (wT + wH)) / (wH + vH);
		let vy = (vH - vh) + (vH - vh) * ratio;

		this.$view.img.css({
			transform: `translateY(${vy}px)`
		})
	}
}
export default class Accordion {
	static instances = [];
	static init () {
		$('[data-accordion]').each(function() {
			Accordion.instances.push(new Accordion($(this), $($(this).data('accordion')), $(this).data('media')));
		});
	}
	constructor($trigger, $target, jugement) {
		this.$trigger = $trigger;
		this.$target = $target && $target.get(0) ? $target : $trigger.next();
		
		if(!this.$target.data().triggers) {
			this.$target.data().triggers = [];
		}
		this.$target.data().triggers.push(this.$trigger);

		if(typeof jugement == 'string') {
			this.jugement = () => window.matchMedia(jugement).matches;
		}
		if(typeof jugement == 'function') {
			this.jugement = jugement;
		}
		this.$trigger.click(e => {
			if(!this.jugement || this.jugement(this)) {
				e.preventDefault();
				this.toggle()
			}
		});
		Accordion.instances.push(this);
	}
	toggle() {
		if(!this.$target.hasClass('is-active')) {
			this.open();
		} else {
			this.close();
		}
	}
	open(duration = 400) {
		var h = this.$target.height();
		var H = this.$target.height('auto').height();

		this.$target.height(h);
		this.$target.stop().animate({ height : H }, duration, () => {
			this.$target.css({ height : 'auto' });
		});
		this.$target.addClass('is-active');
		this.$target.data().triggers.forEach($trigger => {
			$trigger.addClass('is-active');
		});
	}
	close(duration = 400) {
		this.$target.stop().animate({ height : 0 }, duration);
		this.$target.removeClass('is-active');
		this.$target.data().triggers.forEach($trigger => {
			$trigger.removeClass('is-active');
		});
	}
}
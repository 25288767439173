import Accordion from './modules/_Accordion';
import BreakPoint from './modules/_BreakPoint';
import SmoothScroll from './modules/_SmoothScroll';
import ScrollMagician from './modules/_ScrollMagician';
import ScrollImage from './modules/_ScrollImage';
import Form from './modules/_Form';
import GlobalLoading from './common/_GlobalLoading';
import GlobalHeader from './common/_GlobalHeader';

class Common {
	modules = {}
	global = {};

	constructor() {
		this.$view = $(window);
		
		this.modules.BreakPoint = BreakPoint;
		this.modules.BreakPoint.init({ pc : 768, sp : 0 });
		this.modules.Accordion = Accordion;
		this.modules.Accordion.init(accordion => {
			return !accordion.$trigger.data('only') || accordion.$trigger.data('only') == this.modules.BreakPoint.device;
		});
		this.modules.SmoothScroll = SmoothScroll;
		this.modules.SmoothScroll.init();
		this.modules.ScrollMagician = ScrollMagician;
		this.modules.ScrollMagician.init();
		this.modules.ScrollImage = ScrollImage;
		this.modules.ScrollImage.init();
		this.modules.Form = Form;
		this.modules.Form.init();
		
		this.global.loading = new GlobalLoading(this);
		this.global.header = new GlobalHeader(this);

		this.global.loading.loaded.done(() => {
			setTimeout(() => {
				this.modules.ScrollMagician.start();
			}, this.global.loading.is() ? 1500 : 0);
		})
	}
}

window.common = new Common;
export default class Form {
	static init() {
		this.$view = $('.cmn-form');

		console.log("a")
		if(!this.$view.get(0)) {
			return;
		}
		this.$view.find('.cmn-form-upload').each(function() {
			new Form.FileUpload($(this));
		})
		this.$view.find('[data-zipcode]').each(function() {
			new Form.AutoZipcode($(this));
		});
	}

	static FileUpload = class {
		constructor($view) {
			this.$view = $view;
			this.$view.input = {};
			this.$view.input.file = this.$view.find('.input-file');
			this.$view.input.name = this.$view.find('.input-name');
			this.$view.input.data = this.$view.find('.input-data');
			this.$view.button = this.$view.find('.button');
			this.$view.filename = this.$view.find('.filename');
			this.filereader = new FileReader();
			this.file = null;

			this.$view.input.file.change(this.fileSelected.bind(this));
			this.filereader.addEventListener('load', this.fileLoaded.bind(this));
		}
		fileSelected(e) {
			if(this.$view.input.file.get(0).files[0]) {
				this.file = this.$view.input.file.get(0).files[0];
				this.filereader.readAsDataURL(this.file);
			}
		}
		fileLoaded() {
			this.$view.filename.text(this.file.name);
			this.$view.input.name.val(this.file.name);
			this.$view.input.data.val(this.filereader.result);
		}
	}

	static AutoZipcode = class {
		constructor($view) {
			this.$view = $view;
			this.$view.click(this.searchAddress.bind(this));
		}
		searchAddress() {
			let zipcode = this.$view.val();
			zipcode = zipcode.replace("-", "");

			AjaxZip3.zip2addr(
				this.$view.data('zipcode'), '',
				this.$view.data('pref'),
				this.$view.data('city'),
			);
		}

	// 	<script src="/assets/js/ajaxzip3.js"></script>
	// 	<script>
	// 		Common.customSelect();
	
	// 		function yubinFunc(){
	// 			var yubinbango = $("#c_yubin").val();
	// 			yubinbango = yubinbango.replace("-", "");
	// 			$("#c_yubin_search").val(yubinbango);
	// 		}
	// 		$('.autoZip').on('click', function(e){
	// 			e.preventDefault()
	// 			var yubinbango = $("#c_yubin").val();
	// 			yubinbango = yubinbango.replace("-", "")
	// 			AjaxZip3.zip2addr('c_yubin_search','','c_todoufuken','c_ku', 'c_choumei');
	// 			if(yubinbango !== ""){
	// 				setTimeout(function() {
	// 					$(".select-selected").text($("#c_todoufuken").val());
	// 					$(".select-items div").removeClass("same-as-selected");
	// 					$(".select-items").find('#' + $("#c_todoufuken").val() + '').addClass("same-as-selected");
	// 				}, 300);
	// 			}
				
				
				
	// 		})
	}
}